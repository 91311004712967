import { tv } from "tailwind-variants";

export const tvField = tv({
  base: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm focus:ring-1 focus:ring-inset focus:ring-sky-600 text-sm lg:text-base sm:leading-6 disabled:opacity-50 disabled:cursor-not-allowed",
  variants: {
    error: {
      true: "ring-red-600",
    },
  },
});
